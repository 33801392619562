<template>
  <section class="advantages-wrapper">
    <img src="/images/dealers/black-dash.png" class="dash" alt="Separator" />
    <div class="advantages-column">
      <span class="small-title text-black">
        <div class="black-row"></div>
        {{ $t('Brand Support Program') }}
      </span>
      <span class="dealers-sections-title text-black">{{
        $t('Adding Value to Your Every Purchase')
      }}</span>
      <div class="advantages-grid" v-if="!advantagesListLoaded">
        <div
          v-for="(item, index) in 10"
          :key="item"
          class="col-span-1"
          :class="{ active: isActive.includes(index) }"
        >
          <SkeletonLoader height="100px" />
        </div>
      </div>
      <div class="advantages-grid" v-else>
        <div
          v-for="(item, index) in advantagesList"
          :key="index"
          class="accordion advantages-accordion col-span-1"
          :class="{ active: isActive.includes(index) }"
        >
          <button
            class="accordion__cta accordion-btn advantages-accordion-btn font-mont-semibold"
            @click="toggleAccordion(index)"
          >
            <span class="accordion-desc-title"> {{ item.title }} </span>
            <span class="desc" v-show="isActive.includes(index)">
              {{ item.content }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import Icon from '../../../almarwan/components/Icon.vue';
import { useContent } from '../../../composables';
import SkeletonLoader from '../../../components/SkeletonLoader/index.vue';

export default defineComponent({
  name: 'DealersAdvantages',
  components: {
    Icon,
    SkeletonLoader,
  },
  data() {
    return {
      isActive: [],
    };
  },
  setup() {
    const { loadBlocks } = useContent();
    const advantagesList = ref(null);
    const advantagesListLoaded = ref(false);

    useFetch(async () => {
      advantagesList.value = await loadBlocks({
        identifiers: ['dealership_advantages'],
      });
      advantagesList.value = JSON.parse(advantagesList.value?.[0]?.content);
      advantagesListLoaded.value = true;
    });

    return {
      advantagesList,
      advantagesListLoaded,
    };
  },
  methods: {
    toggleAccordion(index) {
      const isActiveIndex = this.isActive.indexOf(index);
      if (isActiveIndex !== -1) {
        this.isActive.splice(isActiveIndex, 1);
      } else {
        this.isActive = [];
        this.isActive.push(index);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.desc {
  transition: all 0.3s ease !important;
  color: #6e768c;
  max-width: 411px;
  padding-top: 1rem;
  font-size: 16px;
  line-height: 26px;
  [dir='rtl'] & {
    font-family: 'NotoKufiArabic-Regular';
    text-align: right;
  }
}

.accordion-btn {
  justify-content: center !important;
}

.advantages-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.25rem;
  margin-top: 2rem;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.accordion-desc-title {
  max-width: 411px;
  [dir='rtl'] & {
    font-family: 'NotoKufiArabic-SemiBold';
  }
}
.accordion {
  background-color: #fff;
  border: 1px solid #dae7ea;
  border-radius: 14px;
  height: 96px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .advantages-accordion {
    padding: 24px 0;
  }
}
.accordion.active {
  height: -moz-fit-content;
  height: fit-content;
  .accordion__cta:after {
    transform: rotate(90deg);
  }
}

.accordion:not(:last-of-type) {
  margin: 0 0 20px;
}

.accordion__cta {
  color: #132528;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font: 1.125em/24px Mont-SemiBold, sans-serif;
  padding-left: 76px;
  width: 100%;
}

[dir='rtl'] .accordion__cta {
  padding-left: unset;
  padding-right: 76px;
}

.accordion__cta:after,
.accordion__cta:before {
  background-color: #132528;
  content: '';
  height: 18px;
  left: 34px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  width: 1.5px;
  [dir='rtl'] & {
    left: unset;
    right: 34px;
  }
}

@media screen and (max-width: 1279px) {
  .accordion__cta {
    font: 0.875em/22px Mont-SemiBold, sans-serif;
    padding: 0 24px 0 60px;
  }
  .accordion__cta:after,
  .accordion__cta:before {
    height: 12px;
    left: 24px;
  }
}

.accordion__cta:before {
  transform: rotate(90deg);
}
</style>
