





















import { defineComponent } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta';
import LazyHydrate from 'vue-lazy-hydration';
import DealersHero from './components/Hero.vue';
import DealersLogos from './components/DealersLogos.vue';
import DealersAdvantages from './components/DealershipAdvantages.vue';
import TrainingAvailability from './components/TrainingAvailability.vue';
import FinancingSupport from './components/FinancingSupport.vue';
import CustomProduct from './components/CustomProduct.vue';
import CustomStories from './components/CustomerStories.vue';
import OurLocation from '../../almarwan/components/OurLocation.vue';
import { useUiHelpers } from '../../composables';
import './components/styles/dealers.scss';

export default defineComponent({
  name: 'DealersPage',
  components: {
    OurLocation,
    TrainingAvailability,
    DealersHero,
    DealersLogos,
    DealersAdvantages,
    FinancingSupport,
    CustomProduct,
    CustomStories,
    LazyHydrate,
  },
  setup() {
    const { getFullUrl } = useUiHelpers();
    return {
      getFullUrl,
    };
  },
  head(): MetaInfo {
    const title = this.$t('dealerTitle').toString();
    const description = this.$t(
      'Discover Al Marwan, brand new heavy equipment dealer in the UAE, Oman, and Saud, including KOBELCO, Powerscreen, ABI.'
    ).toString();
    const baseurl =
      this.$i18n.locale !== 'ar'
        ? 'https://almarwan.com'
        : 'https://almarwan.com/ar';
    return {
      title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: title,
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: title,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description,
        },
        {
          hid: 'url',
          name: 'url',
          content: 'https://almarwan.com' + this.$route.path,
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: 'https://almarwan.com' + this.$route.path,
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: title,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: title,
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: title,
        },
        {
          hid: 'og:apple-mobile-web-app-title',
          name: 'og:apple-mobile-web-app-title',
          content: title,
        },
        {
          property: 'og:image',
          content: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
        },
        {
          property: 'twitter:image',
          content: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.BASE_URL.replace(/\/$/, '') + this.localePath('/dealers'),
        },
      ],
      script: [
        {
          type: 'application/ld+json',
          class: 'saswp-schema-markup-output',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: title,
            url: `${baseurl}/dealers`,
            description: description,
            publisher: {
              '@type': 'Organization',
              name: title,
              logo: {
                '@type': 'ImageObject',
                url: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
                width: 630,
                height: 630,
              },
            },
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': `${baseurl}/dealers`,
            },
          },
        },
      ],
    };
  },
});
