











































































































































































import {
  defineComponent,
  useFetch,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import axios from 'axios';
import SkeletonLoader from '../../../components/SkeletonLoader/index.vue';
import Icon from '../../../almarwan/components/Icon.vue';

export default defineComponent({
  name: 'CustomStories',
  components: { SkeletonLoader, Icon },
  data() {
    return {
      isDragging: false,
      cursorPos: [0, 0],
      el: null,
    };
  },
  setup() {
    const baseUrlCms = 'https://cms.almarwan.com/';
    const latestArticleData = ref([]);
    const {
      app: { i18n },
    } = useContext();
    const getLangCode = () => (i18n.locale === 'ar' ? '&langcode=ar' : '');
    const latestArticleDataLoaded = ref(false);

    const options = {
      headers: {
        'Access-Control-Allow-Origin': true,
        'Content-Type': 'application/json',
      },
    };

    useFetch(async () => {
      const latestArticleResponse = await axios.get(
        `${baseUrlCms}api/blog/articles/latest?_format=json&categories[]=5${getLangCode()}`,
        options
      );

      if (latestArticleResponse.data) {
        latestArticleData.value = latestArticleResponse.data.rows;
        latestArticleDataLoaded.value = true;
      }
    });

    return {
      latestArticleData,
      baseUrlCms,
      latestArticleDataLoaded,
    };
  },
  mounted() {
    window.addEventListener('mouseup', this.onMouseUp);
  },
  destroyed() {
    window.removeEventListener('mouseup', this.onMouseUp);
  },
  methods: {
    onMouseDown(ev) {
      this.cursorPos = [ev.pageX, ev.pageY];
      this.isDragging = true;

      window.addEventListener('mousemove', this.onMouseHold);
    },
    onMouseUp(ev) {
      window.removeEventListener('mousemove', this.onMouseHold);
      this.isDragging = false;
    },
    onMouseHold(ev) {
      ev.preventDefault();
      ev.stopPropagation();

      requestAnimationFrame(() => {
        const delta = [
          ev.pageX - this.cursorPos[0],
          ev.pageY - this.cursorPos[1],
        ];

        this.cursorPos = [ev.pageX, ev.pageY];

        if (!this.$refs.el) return;
        // @ts-ignore: Unreachable code error
        this.$refs.el.scrollBy({
          left: -delta[0],
          top: -delta[1],
        });
      });
    },
    getImageSource() {
      return this.$i18n.locale === 'ar'
        ? '/images/global/achieve-your-business-ar.svg'
        : '/images/global/achieve-your-business.png';
    },
    strippedHtml(content) {
      const regex = /(<([^>]+)>)/gi;
      const txt = content.replace(regex, '');
      return txt.slice(0, 80) + '...';
    },
    getImgSrc(src) {
      return src.startsWith('/') ? src.slice(1) : src;
    },
    getArticleUrl(latestNews) {
      const viewNode: string = latestNews.view_node.replace('ar/', '');
      return '/news/' + latestNews.nid + '/' + viewNode.substring(1);
    },
  },
});
