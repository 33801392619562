





















import { defineComponent } from '@nuxtjs/composition-api';
import CustomBreadcrumbs from '../../../components/custom/CBreadcrumbs.vue'

export default defineComponent({
  name: 'DealersHero',
  components: {
    CustomBreadcrumbs
  },
  data() {
    return {
      breadcrumbs: [{ text: 'Agencies', link: '/dealers' }],
    };
  },
});
