
















import {
  defineComponent,
  useFetch,
  ref,
} from '@nuxtjs/composition-api';
import Icon from '../../../almarwan/components/Icon.vue';
import HTMLContent from '../../../components/HTMLContent.vue';
import { useContent } from '../../../composables';
import SkeletonLoader from '../../../components/SkeletonLoader/index.vue';

export default defineComponent({
  name: 'TrainingAvailability',
  components: { HTMLContent, Icon, SkeletonLoader },

  setup() {
    const { loadBlocks } = useContent();
    const details = ref(null);
    const detailsLoaded = ref(false);
    
    useFetch(async () => {
      details.value = await loadBlocks({
        identifiers: ['dealership_section_1'],
      });
      details.value = details.value[0].content.toString();
      detailsLoaded.value = true;
    })
    return { details, detailsLoaded };
  },
});
