
















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  /**
   * Our only custom button.
   * @displayName Custom Button
   */
  name: 'CustomButton',
  props: {
    /**
     * The type of the button
     * @values button, submit, reset
     */
    type: {
      type: String as () => 'submit' | 'button' | 'reset',
      default: 'button',
      validator: (value: string) =>
        ['button', 'submit', 'reset'].includes(value),
    },
    /**
     * The size of the button
     * @values sm, md, lg, xl
     */
    size: {
      type: String as () => 'sm' | 'md' | 'lg' | 'xl',
      default: 'md',
      validator: (value: string) => ['sm', 'md', 'lg', 'xl'].includes(value),
    },
    /**
     * The variant of the button
     * @values primary, secondary, tertiary, outlined, link
     */
    variant: {
      type: String as () =>
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'outlined'
        | 'link',
      default: 'primary',
      validator: (value: string) =>
        ['primary', 'secondary', 'tertiary', 'outlined', 'link'].includes(
          value
        ),
    },
    /**
     * Status of the button
     * passing it will disable the button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClasses() {
      switch (this.size) {
        case 'sm':
          return ['leading-5 text-xs py-2 px-4', 'gap-1.5'];
        case 'lg':
          return ['leading-6 text-sm py-2.5 px-6', 'gap-3'];
        case 'xl':
          return ['leading-6 text-base py-3.5 px-6', 'gap-4'];
        default:
          return ['leading-6 text-sm py-2.5 px-5', 'gap-2'];
      }
    },
    variantClasses(): {
      primary: string;
      secondary: string;
      tertiary: string;
      outlined: string;
      link: string;
    } {
      return {
        primary:
          'bg-primary-500 !text-white border border-solid border-primary-500 hover:bg-primary-600 hover:border-primary-600 active:bg-primary-600 active:text-primary-200 active:border-primary-700 focus:bg-primary-600 focus:border-0 focus:shadow-sm disabled:bg-primary-300 disabled:text-primary-100 disabled:border-primary-200',
        secondary:
          'bg-primary-50 border border-solid border-primary-100 hover:bg-primary-100 hover:text-primary-600 hover:border-0 active:bg-primary-100 active:text-primary-500 active:border active:border-primary-200 focus:bg-primary-100 focus:text-primary-500 focus:border focus:border-primary-200 disabled:bg-primary-50 disabled:text-primary-300 disabled:border disabled:border-primary-100',
        tertiary:
          'bg-color-white hover:text-primary-600 active:text-primary-400 focus:border focus:border-solid focus:border-primary-500 disabled:text-primary-300',
        outlined:
          'bg-transparent border border-solid border-primary-500 hover:border-primary-400 active:bg-primary-50 active:text-primary-400 active:border-primary-400 focus:border-3 focus:border-primary-400 disabled:text-primary-300 disabled:border-primary-300',
        link: 'bg-transparent border-0 hover:text-primary-600 active:text-primary-400 focus:border focus:border-solid focus:border-primary-500 disabled:text-primary-300',
      };
    },
  },
});
