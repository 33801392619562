











































































import {
  defineComponent,
  ref,
  useFetch,
} from '@nuxtjs/composition-api';
import { useContent } from '../../../composables';
import SkeletonLoader from '../../../components/SkeletonLoader/index.vue';
import CustomButton from '../../../components/custom/CButtonBasic.vue'
import './styles/dealers.scss';

export default defineComponent({
  name: 'DealersLogos',
  components: {
    SkeletonLoader,
    CustomHeading: () => import('../../../components/custom/CDashedTitle.vue'),
    CustomButton
  },
  props: {
    heading: {
      type: String,
      default: 'Your Authorized Distributor for Global Brands Of Machinery and Attachments'
    },
    logosTitle: {
      type: String,
      default: 'Explore the brands we represent'
    },
    customTitle: {
      type: String,
      default: ''
    },
    learnMoreBtn : {
      type: Boolean,
      default: false
    },
    btnUrl: {
      type: String,
      default: '/'
    },
    authBadge: {
      type: Boolean,
      default: false
    },
    blockId: {
      type: String,
      default: 'dealership_brands'
    }

  },
  setup(props) {
    const { loadBlocks } = useContent();
    const brandsList = ref(null);
    const brandListLoaded = ref(false);

    useFetch(async () => {
      brandsList.value = await loadBlocks({
        identifiers: [props.blockId],
      });
      brandsList.value = JSON.parse(brandsList.value?.[0]?.content);
      brandsList.value = brandsList.value.filter((el) => {
        return el.type === 'dealer';
      });
      brandListLoaded.value = true;
    });
    
    return {
      brandsList,
      brandListLoaded,
    };
  },
  methods: {
    getImageSource() {
      const locale = this.$i18n.locale;
      return `/images/global/${locale === 'ar' ? 'exclusive-ar' : 'exclusive'}.svg`;
    },
    getAuthBadge() {
      const locale = this.$i18n.locale;
      return `/home/${locale === 'ar' ? 'authorized-ar' : 'authorized'}.svg`;
    },
    goToDetails(block_identifier, id) {
      block_identifier = block_identifier + '-' + id;
      this.$router.push({ name: 'DealershipDetails', params: { block_identifier } });
    }
  },
});
