<template>
  <div>
    <section v-if="!detailsLoaded" class="images-text-section section-wrapper">
      <div class="images-text-section-grid">
        <div class="col-img relative">
          <SkeletonLoader height="500px" />
        </div>
        <div class="text-col flex flex-col gap-8">
          <SkeletonLoader height="500px" />
        </div>
      </div>
    </section>
    <HTMLContent v-else :content="details" />
  </div>
</template>

<script>
import { defineComponent, useFetch, ref } from '@nuxtjs/composition-api';
import Icon from '../../../almarwan/components/Icon.vue';
import { useContent } from '../../../composables';
import HTMLContent from '../../../components/HTMLContent.vue';
import SkeletonLoader from '../../../components/SkeletonLoader/index.vue';

export default defineComponent({
  name: 'FinancingSupport',
  components: { SkeletonLoader, HTMLContent, Icon },

  setup() {
    const { loadBlocks } = useContent();
    const details = ref('');
    const detailsLoaded = ref(true);
    useFetch(async () => {
      details.value = await loadBlocks({
        identifiers: ['dealership_section_3'],
      });
      details.value = details.value[0].content;
      detailsLoaded.value = true;
    });
    return { details, detailsLoaded };
  },
});
</script>
