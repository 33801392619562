


















import { defineComponent } from '@nuxtjs/composition-api';
import CompanyInfo from '~/almarwan/components/CompanyInfo.vue';

export default defineComponent({
  name: 'OurLocation',
  components: {
    CompanyInfo
  }
});
